import type { ActionArgs, LoaderArgs, V2_MetaFunction } from '@remix-run/node'
import { json, redirect } from '@remix-run/node'
import { Form, useActionData, useLoaderData, useNavigation, useSearchParams } from '@remix-run/react'
import { useEffect, useRef } from 'react'
import GithubLoginButton from '~/components/GithubLoginButton'
import Alert from '~/components/UIKit/Alert'
import Button from '~/components/UIKit/Button'
import Link from '~/components/UIKit/Link'
import Panel from '~/components/UIKit/Panel'
import { getLeagueDetailsForInviteCode } from '~/models/league.server'

import { verifyLogin } from '~/models/user.server'
import { createUserSession, getUserId } from '~/session.server'
import { safeRedirect, validateEmail } from '~/utils'

const inviteLinkPattern = /^\/leagues\/invite\/([a-zA-Z0-9_-]+)$/

export const loader = async({ request }: LoaderArgs) => {
  const userId = await getUserId(request)
  if (userId) return redirect('/')

  const url = new URL(request.url)
  const redirectTo = url.searchParams.get('redirectTo')

  let leagueInviteCode: string | undefined = undefined
  let invitedLeagueName: string | undefined = undefined
  if (redirectTo) {
    const match = redirectTo.match(inviteLinkPattern)
    if (match) {
      leagueInviteCode = match[1]
      const league = await getLeagueDetailsForInviteCode({ inviteCode: leagueInviteCode })
      invitedLeagueName = league?.name
    }
  }

  return json({ leagueInviteCode, invitedLeagueName })
}

export const action = async({ request }: ActionArgs) => {
  const formData = await request.formData()
  const email = formData.get('email')
  const password = formData.get('password')
  const redirectTo = safeRedirect(formData.get('redirectTo'), '/leagues')
  const remember = formData.get('remember')

  if (!validateEmail(email)) {
    return json(
      { errors: { email: 'Email is invalid', password: null } },
      { status: 400 },
    )
  }

  if (typeof password !== 'string' || password.length === 0) {
    return json(
      { errors: { email: null, password: 'Password is required' } },
      { status: 400 },
    )
  }

  const user = await verifyLogin(email, password)

  if (!user) {
    return json(
      { errors: { email: 'Invalid email or password', password: null } },
      { status: 400 },
    )
  }

  return createUserSession({
    redirectTo,
    remember: remember === 'on' ? true : false,
    request,
    userId: user.id,
  })
}

export const meta: V2_MetaFunction = () => [{ title: 'Log in - pong.rocks' }]

export default function LoginPage() {
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo') || '/leagues'
  const { leagueInviteCode, invitedLeagueName } = useLoaderData<typeof loader>()
  const actionData = useActionData<typeof action>()
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const navigation = useNavigation()
  const isSubmitting = navigation.state === 'submitting'

  useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus()
    } else if (actionData?.errors?.password) {
      passwordRef.current?.focus()
    }
  }, [actionData])

  return (
    <div className="flex min-h-full flex-col justify-center p-4 sm:p-8">
      <Panel className="mx-auto w-full max-w-md">
        <Form method="post" className="space-y-6">
          {invitedLeagueName ?
            <Alert variant="subtle" className="text-lg">
              Log in to join league <span className="font-bold">{invitedLeagueName}</span>:
            </Alert> :
            <h2 className="text-xl font-bold">Log in</h2>
          }
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium"
            >
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                ref={emailRef}
                required
                autoFocus={true}
                name="email"
                type="email"
                autoComplete="email"
                aria-invalid={actionData?.errors?.email ? true : undefined}
                aria-describedby="email-error"
                className="w-full rounded border border-gray-500 px-2 py-1 text-lg"
              />
              {actionData?.errors?.email ? (
                <Alert variant="danger" id="email-error" className="mt-4">
                  {actionData.errors.email}
                </Alert>
              ) : null}
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium"
            >
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                ref={passwordRef}
                required
                name="password"
                type="password"
                autoComplete="current-password"
                aria-invalid={actionData?.errors?.password ? true : undefined}
                aria-describedby="password-error"
                className="w-full rounded border border-gray-500 px-2 py-1 text-lg"
              />
              {actionData?.errors?.password ? (
                <Alert variant="danger" id="password-error" className="mt-4">
                  {actionData.errors.password}
                </Alert>
              ) : null}
            </div>
          </div>

          <input type="hidden" name="redirectTo" value={redirectTo} />
          <Button type="submit" className="w-full" disabled={isSubmitting}>
            {isSubmitting ? 'Logging in...' : 'Log in'}
          </Button>
          <div className="flex items-start justify-between">
            <div className="flex items-center">
              <input
                id="remember"
                name="remember"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label
                htmlFor="remember"
                className="ml-2 block text-sm"
              >
                Remember me
              </label>
            </div>
            <div className="text-right text-sm text-gray-600">
              <span className="font-bold">Don't have an account?</span>{' '}
              <Link
                to={{
                  pathname: '/join',
                  search: searchParams.toString(),
                }}
              >
                Sign up
              </Link>
              <br />
              <Link
                to="/forgot-password"
              >
                Forgot your password?
              </Link>
            </div>
          </div>
        </Form>

        <div className="flex flex-row gap-2 items-center mt-4">
          <div className="flex-1 border-b border-violet-400" />
          <div className="text-gray-500 font-bold">or</div>
          <div className="flex-1 border-b border-violet-400" />
        </div>

        <GithubLoginButton leagueInviteCode={leagueInviteCode} className="w-full mt-4" />
      </Panel>
    </div>
  )
}
